import React, {
  FC,
} from 'react';
import { Input, Select } from 'components/FormControls';
import { Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnterpriseBroadcastFormGeneratorFilter } from 'types/BroadcastForm';
import { IOptions } from 'components/Inputs/Select';
import { useSelector } from 'react-redux';
import { selectCustomFieldNames } from 'reducers/UserInfo';
import { Control } from 'react-hook-form';

type EbfGeneratorFiltersProps = {
  data: EnterpriseBroadcastFormGeneratorFilter[]
  onAddFilter: () => void
  onRemoveFilter: (Id: string) => void
  loading: boolean
  control: Control
};

const filterTypeOptions: IOptions[] = [{ label: 'Include', value: 'Include' }, { label: 'Exclude', value: 'Exclude' }];
const validateCustomFields = ['Employee Status#v1sync', 'Employee Type#v1sync', 'Termination Reason#v1sync'];

export const EbfGeneratorFilters: FC<EbfGeneratorFiltersProps> = ({
  data,
  onRemoveFilter,
  onAddFilter,
  loading,
  control,
}) => {
  const accountCustomFields = useSelector(selectCustomFieldNames);
  const customFieldOptions = React.useMemo(() => {
    const options: IOptions[] = [
      { label: 'Job Classification', value: 'Job Classification' },
      { label: 'Role', value: 'Role' }];
    if (!accountCustomFields.length) {
      return options;
    }
    accountCustomFields.map((cf) => {
      const validateCustomField = validateCustomFields.find((f) => f.toLowerCase() === cf.toLowerCase());
      if (validateCustomField) {
        options.push({ label: cf.split('#')[0].trim(), value: cf });
      }
    });
    return options;
  }, [accountCustomFields]);

  const addButtonDisabled = loading || data.length >= 5;

  return (
    <>
      <Row>
        <Col xs="12" md="12">
          <h4 className="mt-4 mb-4">Filters:</h4>
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xs="12" md="12">
          {data.map((filter, index) => (
            <Row className="filter-row-children">
              <Col xs="1" md="1" key={`Filters.close.${filter.Id}`} className="filter-col-small">
                <FontAwesomeIcon icon="trash-alt" color="#FF7052" size="1x" onClick={() => onRemoveFilter(filter.Id)} />
              </Col>
              <Col xs="3" md="3" key={`FilterType.${filter.Id}`} className="without-padding-left">
                <Form.Group>
                  <Select
                    id={`Filters.${index}.FilterType`}
                    name={`Filters.${index}.FilterType`}
                    label="Filter"
                    required
                    control={control}
                    options={filterTypeOptions}
                    defaultValue="Include"
                  />
                </Form.Group>
              </Col>
              <Col xs="1" md="1" key={`Filters.if.${filter.Id}`} className="filter-col-small without-padding">
                IF
              </Col>
              <Col xs="3" md="3" key={`FilterField.${filter.Id}`}>
                <Form.Group>
                  <Select
                    id={`Filters.${index}.FilterField`}
                    name={`Filters.${index}.FilterField`}
                    label="Profile Field"
                    required
                    control={control}
                    options={customFieldOptions}
                    defaultValue="Job Classification"
                  />
                </Form.Group>
              </Col>
              <Col xs="1" md="1" key={`Filters.isin.${filter.Id}`} className="filter-col without-padding">
                IS IN
              </Col>
              <Col xs="3" md="3" key={`FilterValue.${filter.Id}`}>
                <Form.Group className="extra-margin-bottom">
                  <Input
                    id={`Filters.${index}.Id`}
                    name={`Filters.${index}.Id`}
                    type="hidden"
                    value={filter.Id}
                    defaultValue={filter.Id}
                    control={control}
                  />
                  <Input
                    id={`Filters.${index}.FilterValue`}
                    name={`Filters.${index}.FilterValue`}
                    type="text"
                    label="Values"
                    placeholder="Enter a comma seperated list of values"
                    defaultValue={filter.FilterValue}
                    control={control}
                    maxLength={200}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12">
          <button
            type="button"
            className="btn btn-primary mt-4 mb-4"
            disabled={addButtonDisabled}
            onClick={onAddFilter}
          >
            Add
          </button>
        </Col>
      </Row>
    </>
  );
};

import { Switch, Route } from 'react-router-dom';
import React from 'react';
import { Container } from 'react-bootstrap';
import Mfa from 'pages/Administration/Mfa';
import DS from 'pages/DS';
import { useIsLoginValid } from 'hooks/useIsLoginValid';
import ChangePassword from '../pages/Administration/ChangePassword'
import Activate from '../pages/Administration/Activate'
import App from '../app/App';
import Login from '../pages/Login';
import CookieExchange from '../pages/CookieExchange';
import LinkVoicefriend from '../pages/LinkVoicefriend';
import LinkCaremerge from '../pages/LinkCaremerge';
import IconRedirect from '../pages/IconRedirect';
import Unsubscribe from '../pages/Administration/Unsubscribe';
import BroadcastFormSession from '../pages/BroadcastForm/BroadcastFormSession';
import Error403Page from '../pages/Errors/403';
import Error404Page from '../pages/Errors/404';
import Error500Page from '../pages/Errors/500';

function RootRoutes() {
  const isLoginValid = useIsLoginValid();

  return (
    <Switch>
      <Route path="/ds" component={DS} />
      <Route path="/login" component={Login} />
      <Route path="/auth-saml" component={CookieExchange} />
      <Route
        path="/changePassword"
        component={ChangePassword}
      />
      <Route
        path="/mfa"
        component={Mfa}
      />
      <Route
        path="/Activate"
        component={Activate}
      />
      <Route
        path="/linkVoicefriend"
        component={LinkVoicefriend}
      />
      <Route
        path="/linkGoIcon"
        component={LinkCaremerge}
      />
      <Route
        path="/IconRedirect"
        component={IconRedirect}
      />
      <Route
        path="/unsubscribe"
        component={Unsubscribe}
      />
      <Route
        path="/form/:formId"
        component={BroadcastFormSession}
      />
      <Route
        path="/aspx/**"
        render={({ location }) => {
          window.location.pathname = location.pathname;
        }}
      />

      {!isLoginValid && [
        <Route
          path="/404"
          render={() => (
            <Container>
              <div className="error-page-wrapper">
                <Error404Page />
              </div>
            </Container>
          )}
        />,
        <Route
          path="/403"
          render={() => (
            <Container>
              <div className="error-page-wrapper">
                <Error403Page />
              </div>
            </Container>
          )}
        />,
        <Route
          path="/500"
          render={() => (
            <Container>
              <div className="error-page-wrapper">
                <Error500Page />
              </div>
            </Container>
          )}
        />,
      ]}

      <Route
        path="/"
        component={App}
      />

    </Switch>
  );
}

export default RootRoutes;
